import { useState } from 'react';

import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SearchIcon from '@material-ui/icons/Search';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { Spacer, Table, Text } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import { useQuery } from 'hooks/useQuery';
import { PageTitle } from 'pages/Layout/PageTitle';
import { accountService } from 'services';

import { RoleDialog } from './RoleDialog';
import { useStyles } from './styles';

const columnsUser = [{ label: 'Nome' }, { label: 'Email' }, { label: 'Grupo de Usuário' }];
const columnsRole = [
  { label: 'Nomenclatura' },
  { label: 'Descrição do Grupo' },
  { label: <VisibilityIcon />, align: 'center' },
];

export const Permissions = () => {
  const roleUser = JSON.parse(localStorage.getItem('@Gaia:user'));

  const classes = useStyles();

  const [search, setSearch] = useState('');

  const [isDetailsOpen, setIsDetailsState] = useBoolean();
  const [isDetailsOpenUser, setIsDetailsStateUser] = useBoolean();
  const [data, setData] = useState({});

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [pageRole, setPageRole] = useState(0);
  const [rowsPerPageRole, setRowsPerPageRole] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePageRole = (event, newPage) => {
    setPageRole(newPage);
  };

  const handleChangeRowsPerPageRole = (event) => {
    setRowsPerPageRole(parseInt(event.target.value, 10));
    setPageRole(0);
  };

  const handleCloseInformationRole = () => {
    setIsDetailsState.toFalse();
  };

  const handleOpenInformationRole = (e, item) => {
    setIsDetailsState.toTrue();
    setData(item);
  };

  const handleCloseInformationUser = () => {
    setIsDetailsStateUser.toFalse();
  };

  const handleOpenInformationUser = (e, item) => {
    setIsDetailsStateUser.toTrue();
    setData(item);
  };
  const [userData, , loadingUserData, refetchUserData] = useQuery(
    () => accountService.listUsers(),
    [],
  );

  const [roleData, , loadingRoleData, refetchRole] = useQuery(
    () => accountService.listUserRoles(),
    [],
  );

  console.log(roleUser);
  const filteredRoleData = (roleData || []).filter((item) => {
    if (roleUser?.role?.id === 6) return true;
    return item.description.toUpperCase() !== 'TI';
  });
  console.log(filteredRoleData);

  const formattedUsers = (userData || []).map((b) => {
    return {
      ...b,
    };
  });

  const formattedRoles = (filteredRoleData || []).map((b) => {
    return {
      ...b,
    };
  });

  const filteredRoles = formattedRoles.filter((role) => {
    const upperRoleAlias = role.alias?.toUpperCase();
    const lowerRoleAlias = role.alias?.toLowerCase();

    const upperRole = role.description?.toUpperCase();
    const lowerRole = role.description?.toLowerCase();

    return (
      upperRoleAlias?.includes(search) ||
      lowerRoleAlias?.includes(search) ||
      upperRole?.includes(search) ||
      lowerRole?.includes(search)
    );
  });

  const filteredUsers = formattedUsers.filter((user) => {
    const upperName = user.name?.toUpperCase();
    const lowerName = user.name?.toLowerCase();

    const upperEmail = user.email?.toUpperCase();
    const lowerEmail = user.email?.toLowerCase();

    const upperRole = user.role?.alias?.toUpperCase();
    const lowerRole = user.role?.alias?.toLowerCase();

    return (
      upperName?.includes(search) ||
      lowerName?.includes(search) ||
      upperEmail?.includes(search) ||
      lowerEmail?.includes(search) ||
      upperRole?.includes(search) ||
      lowerRole?.includes(search)
    );
  });

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearch(value);
  };

  // const filteredRolesNew = filteredRoles && filteredRoles.filter((item) => item.id !== 6);

  return (
    <>
      <PageTitle>Permissionamento</PageTitle>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Text
            label='Pesquisar'
            size='small'
            variant='outlined'
            endAdornment={<SearchIcon />}
            value={search}
            onChange={handleSearchChange}
          />
        </Grid>
      </Grid>

      <Spacer axis='vertical' size={24} />

      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={6} lg={6}>
          <Typography variant='h6'>Usuários - Atribuir Permissões Adicionais</Typography>
          <Spacer axis='vertical' size={24} />
          <Paper className={classes.paperBord} variant='outlined'>
            <Table
              headers={columnsUser}
              striped
              loading={loadingUserData}
              actionsLabel={<VisibilityIcon />}
              emptyMessage='Nenhum registro encontrado.'
              onActionsClick={handleOpenInformationUser}
              data={filteredUsers}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              disableNumeration
            >
              {filteredUsers?.map((user) => (
                <TableRow key={user?.id}>
                  <TableCell>{user?.name}</TableCell>
                  <TableCell>{user?.email}</TableCell>
                  <TableCell>{user?.role?.alias}</TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>

        <UserDialog
          open={isDetailsOpenUser}
          handleClose={handleCloseInformationUser}
          user={data}
          reloadUsers={refetchUserData}
          reloadRoles={refetchRole}
        /> */}

        <Grid item xs={12} md={12} lg={12}>
          <Typography variant='h6'>Grupo de Usuários - Atribuir Permissões</Typography>
          <Spacer axis='vertical' size={24} />
          <Paper className={classes.paperBord} variant='outlined'>
            <Table
              headers={columnsRole}
              striped
              loading={loadingRoleData}
              // actionsLabel={<VisibilityIcon />}
              emptyMessage='Nenhum registro encontrado.'
              // onActionsClick={handleOpenInformationRole}
              data={filteredRoles}
              page={pageRole}
              rowsPerPage={rowsPerPageRole}
              onChangePage={handleChangePageRole}
              onChangeRowsPerPage={handleChangeRowsPerPageRole}
              disableNumeration
            >
              {filteredRoles?.map((role) => (
                <TableRow key={role?.id}>
                  <TableCell>{role?.alias}</TableCell>
                  <TableCell>{role?.description}</TableCell>
                  <TableCell align='center'>
                    <IconButton color='primary' onClick={(e) => handleOpenInformationRole(e, role)}>
                      <AddCircleIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </Paper>
        </Grid>

        <RoleDialog
          open={isDetailsOpen}
          handleClose={handleCloseInformationRole}
          role={data}
          reloadRoles={refetchRole}
          reloadUsers={refetchUserData}
        />
      </Grid>
    </>
  );
};
