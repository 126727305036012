import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { AuthContext } from 'hooks/useAuth';
import { useQuery } from 'hooks/useQuery';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DriverScreen } from './DriverScreen';
import { LeaderScreen } from './LeaderScreen';
import { TechnicianScreen } from './TechnicianScreen';

export const TechncianCalendar = () => {
  const user = useContextSelector(AuthContext, (context) => context.user);

  const roleId = user.role.id;

  const [tecs, , ,] = useQuery(() => inHomeService.findInHomeTec(), []);

  const optionsTecs = (tecs || [])?.map((a) => ({
    value: a.user_id.id,
    label: a.user_id.name,
    ativo: a.active,
    type_tec: a.type_tec,
  }));

  let tecOrAdmin;
  let isTec = false;
  let isLeader = false;

  const activeTec = optionsTecs.filter((item) => item.ativo);
  let isDriver = false;
  console.log(activeTec);

  // Verifica se o usuário é Reparador
  if (activeTec.some((item) => item.value === user.id && item.type_tec === 'Reparador')) {
    tecOrAdmin = `Técnico: ${user.name}`;
    isTec = true;
  }
  // Verifica se o usuário é Motorista
  else if (activeTec.some((item) => item.value === user.id && item.type_tec === 'Motorista')) {
    tecOrAdmin = `Motorista: ${user.name}`;
    isDriver = true;
  } else if (roleId === 8) {
    tecOrAdmin = `Administrador: ${user.name}`;
    isLeader = true;
  }

  console.log(activeTec[0]?.value, user.id);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h1' style={{ fontSize: '20px', fontWeight: 'bold' }}>
            Agenda In Home - {tecOrAdmin}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {isTec && (
            <>
              <TechnicianScreen />
            </>
          )}
          {isLeader && (
            <>
              <LeaderScreen />
            </>
          )}
          {isDriver && (
            <>
              <DriverScreen user={user.id} />
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};
