/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { TextField, Typography } from '@mui/material';
import { Button, Dialog } from 'components';
import formatter from 'date-fns/format';
import { Form, Formik } from 'formik';
import { inHomeService } from 'services';

export const DialogSelectData = ({ open, onClose, selectedEvent, onSave }) => {
  if (!open) return null;

  const [closestTech, setClosestTech] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { id } = selectedEvent;
  const handleUpdateScheduleDate = async (values) => {
    try {
      const { data } = await inHomeService.updateScheduleDate({
        id: id,
        newData: values.input_data,
      });
      toast.success('Agendamento realizado com sucesso!');
      onSave();
      onClose();
    } catch (error) {
      console.error(error?.response?.data?.message || error.message);
      toast.error(error?.response?.data?.message || 'Erro ao marcar agendamento');
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const fetchData = useCallback(async () => {
    if (!selectedEvent?.id) return; // Verifica se selectedEvent está disponível
    setIsLoading(true);
    try {
      // Primeiro busca a informação adicional do serviço
      const { data } = await inHomeService.findInfoAdcByIdService({ service_id: selectedEvent.id });

      if (!data || data.length === 0) {
        setIsLoading(false);
        return;
      }

      // Em seguida, busca a vinculação dos técnicos
      const { data: vincs } = await inHomeService.findVincsProdTecByIdProd({
        prod: data[0].group_prod,
      });

      const address = `${data[0]?.street} ${data[0]?.number}, ${data[0]?.city}, ${data[0]?.state}`;

      const newCoords = await getCoordinates(address);
      let closestDistance = Infinity; // Inicializa a menor distância como Infinity
      let closest = null;

      // Para cada técnico vinculado, busque os serviços
      for (const tech of vincs) {
        const { data: services } = await inHomeService.findServicesByTec({ id_tec: tech?.tec?.id });

        for (const service of services) {
          const serviceAddress = `${service.street} ${service.number}, ${service.city}, ${service.state}`;
          const serviceCoords = await getCoordinates(serviceAddress);

          if (serviceCoords) {
            const distance = haversineDistance(newCoords, serviceCoords);

            // Verifica se a distância atual é menor que a menor distância encontrada
            if (distance < closestDistance) {
              closestDistance = distance;
              closest = { tech, distance, service, services };
            }
          }
        }
      }

      let countServicesByTec = 0;
      if (closest) {
        const serviceDate = new Date(closest.service.date_Scheduling);

        // Conta quantos serviços o técnico mais próximo tem no mesmo dia
        countServicesByTec = closest.services.filter((svc) => {
          const currentServiceDate = new Date(svc.date_Scheduling);
          return (
            serviceDate.getFullYear() === currentServiceDate.getFullYear() &&
            serviceDate.getMonth() === currentServiceDate.getMonth() &&
            serviceDate.getDate() === currentServiceDate.getDate()
          );
        }).length;
      }

      setClosestTech({ closest, countServicesByTec });
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedEvent?.id]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  return (
    <Dialog maxWidth={'md'} open={open}>
      <Dialog.Title>Selecione a data do atendimento</Dialog.Title>
      <Dialog.Content>
        <Box border={1} borderRadius={4} p={2} textAlign='center' color='#512da8' mb={2}>
          {isLoading ? (
            <Typography variant='body1'>Carregando sugestão de técnico...</Typography>
          ) : closestTech && closestTech.closest ? (
            <>
              <Typography variant='h6'>
                Técnico com atendimento mais próximo: {closestTech.closest.tech?.tec.name}
              </Typography>
              <Typography variant='body1'>
                Endereço:
                {`${closestTech.closest.service?.street} ${closestTech.closest.service?.number}, 
                  ${closestTech.closest.service?.city}, ${closestTech.closest.service?.state}`}
              </Typography>
              <Typography variant='body1'>
                Distância: {closestTech.closest.distance?.toFixed(2)} km
              </Typography>
              <Typography variant='body1'>
                Data do atendimento: {formatDate(closestTech.closest.service?.date_Scheduling)}
              </Typography>
              <Typography>Atendimentos no mesmo dia: {closestTech.countServicesByTec}</Typography>
            </>
          ) : (
            <Typography variant='body1'>Nenhuma sugestão encontrada.</Typography>
          )}
        </Box>
        <Formik
          initialValues={{ input_data: '' }}
          onSubmit={(values) => {
            handleUpdateScheduleDate(values);
          }}
        >
          {({ values, setFieldValue, isValid, dirty }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant='h6' gutterBottom>
                    Selecione a Data e Hora
                  </Typography>
                  <TextField
                    label='Data e Hora'
                    name='input_data'
                    type='datetime-local'
                    fullWidth
                    value={values.input_data}
                    onChange={(e) => setFieldValue('input_data', e.target.value)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      inputProps: {
                        min: getCurrentDateTime(),
                      },
                    }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button type='submit' disabled={!isValid || !dirty}>
                    Salvar
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog.Content>
    </Dialog>
  );
};

const key = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
// Funções auxiliares
const getCoordinates = async (address) => {
  const response = await fetch(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address,
    )}&key=${key}`,
  );
  const data = await response.json();
  if (data.results.length > 0) {
    const location = data.results[0].geometry.location;
    return { lat: location.lat, lng: location.lng };
  }
  return null;
};

const haversineDistance = (coords1, coords2) => {
  const R = 6371; // Raio da Terra em km
  const dLat = (coords2.lat - coords1.lat) * (Math.PI / 180);
  const dLng = (coords2.lng - coords1.lng) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(coords1.lat * (Math.PI / 180)) *
      Math.cos(coords2.lat * (Math.PI / 180)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distância em km
};
