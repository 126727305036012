import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ReactComponent as PritingInvoice } from 'assets/images/selection.svg';
import { Button } from 'components';
import { useBoolean } from 'hooks/useBoolean';
import { PageTitle } from 'pages/Layout/PageTitle';
import { api } from 'services';

import { useStyles } from './styles';

export const MirrorInvoice = () => {
  const classes = useStyles();

  const [importing, setImporting] = useState(false);
  const [financialValidated, setFinancialValidated] = useBoolean(false);

  const inputFileRef = useRef(null);

  const handleImportClick = () => {
    setImporting(true);
    inputFileRef.current.click();
  };

  const handleFileChange = async (e) => {
    toast.info('Importando XML...');
    try {
      const files = e.target.files; // Pegue todos os arquivos selecionados

      // Verifique se todos os arquivos são XML
      for (let i = 0; i < files.length; i++) {
        if (files[i].type !== 'text/xml') {
          toast.error('Todos os arquivos devem ser do tipo XML');
          return;
        }
      }

      const promises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = async (event) => {
            const result = event.target.result;

            try {
              const response = await api.post('/provider-tiny/mirror-invoice', result, {
                headers: {
                  'Content-Type': 'application/xml',
                },
                params: {
                  financialValidated,
                },
              });

              const { data } = response;

              if (data?.xml) {
                const blob = new Blob([data?.xml], { type: 'application/xml' });
                const url = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = `XML_${data?.numero}.xml`;
                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);

                toast.success(`NF ${data?.numero} gerada com sucesso`);
              } else {
                toast.error('Não foi possível encontrar o link da NF');
              }

              resolve();
            } catch (err) {
              reject(err);
            }
          };

          reader.readAsText(file);
        });
      });

      await Promise.all(promises); // Aguarde todos os arquivos serem enviados
      setImporting(false);
    } catch (err) {
      console.log(err);
      setImporting(false);
      toast.error(err.response?.data?.message || 'Erro ao importar, tente novamente!');
    } finally {
      inputFileRef.current.value = ''; // Reseta o input após a importação
    }
  };

  return (
    <>
      <input
        ref={inputFileRef}
        type='file'
        name='xml'
        multiple
        onChange={(e) => [setImporting(true), handleFileChange(e)]}
        accept='text/xml'
        hidden
      />

      <PageTitle>Espelhar nota de entrada</PageTitle>

      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <Button
            variant='contained'
            color='primary'
            fullWidth
            onClick={handleImportClick}
            type=''
            loading={importing}
            startIcon={<FileUploadIcon />}
          >
            Importar XML
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
          <FormControlLabel
            control={
              <Checkbox
                disabled={importing}
                color='secondary'
                size='medium'
                onChange={() => setFinancialValidated.toggle()}
                checked={financialValidated}
              />
            }
            label='Validado com o financeiro'
          />
        </Grid> */}
      </Grid>
      <Grid className={classes.containerImg}>
        <PritingInvoice className={classes.trashImage} />
      </Grid>
    </>
  );
};
