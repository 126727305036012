import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Button, Dialog, Table } from 'components';
import { inHomeService } from 'services';

const columns = [
  { label: 'PartNumber' },
  { label: 'Quantidade Levada' },
  { label: 'Quantidade Usada' },
  { label: 'Ação', align: 'center' },
  //   { label: 'Validar' },
];

export const DialogPartsForService = ({ open, onClose, line }) => {
  if (!open) return null;
  const [parts, setParts] = useState([]);

  const fetchData = async () => {
    const { data } = await inHomeService.findPartByService({ service_id: line.service_id });
    setParts(data);
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handleRegisterDivergence = async (item) => {
    try {
      console.log(item.id);
      const { data } = await inHomeService.registerDivergenceParts({ id_part: item.id });
      toast.success('Divergência registrada com sucesso.');
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || 'Erro ao registrar divergência da peça');
    }
  };

  const handleRegisterValidate = async (item) => {
    try {
      const { data } = await inHomeService.registerValidateParts({ id_part: item.id });
      toast.success('Validação registrada com sucesso.');
      fetchData();
    } catch (error) {
      console.error(error);
      toast.error(error?.response?.data?.message || 'Erro ao registrar Validação da peça');
    }
  };

  const handleFinishConferenceParts = async () => {
    if (parts.some((item) => !item.conference)) {
      return toast.error('Existem peças sem registro de divergência ou validação.');
    }
    const { data } = await inHomeService.finishConferenceParts({ service_id: line.service_id });
    toast.success('Conferência finalizada com sucesso.');
    onClose();
  };
  return (
    <Dialog maxWidth={'md'} open={open}>
      <Dialog.Title onClose={onClose}>Peças - Protocolo {line.protocol}</Dialog.Title>
      <Dialog.Content>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Table headers={columns} disableNumeration>
              {parts.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.part_number}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                  <TableCell>{item.amount_used ?? 'Não utilizado'}</TableCell>
                  {!item.conference && (
                    <TableCell align='center'>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Button onClick={() => handleRegisterDivergence(item)} color='secondary'>
                            <DoNotDisturbIcon />
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button onClick={() => handleRegisterValidate(item)}>
                            <ThumbUpIcon />
                          </Button>
                        </Grid>
                      </Grid>
                    </TableCell>
                  )}
                  {item.conference === 'validado' && (
                    <TableCell align='center'>
                      <Typography style={{ color: 'green' }}>Validado</Typography>
                    </TableCell>
                  )}
                  {item.conference === 'divergente' && (
                    <TableCell align='center'>
                      <Typography style={{ color: 'red' }}>Divergente</Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </Table>
          </Grid>
        </Grid>
      </Dialog.Content>
      <Dialog.Actions>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button color='secondary' fullWidth onClick={onClose}>
              Fechar
            </Button>
          </Grid>
          <Grid item xs={6} onClick={() => handleFinishConferenceParts()}>
            <Button fullWidth>Finalizar</Button>
          </Grid>
        </Grid>
      </Dialog.Actions>
    </Dialog>
  );
};
