import { useContext } from 'react';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Button, DatePicker, Select, Text, Time } from 'components';
import { Field, Form, Formik } from 'formik';
import { PageTitle } from 'pages/Layout/PageTitle';
import * as Yup from 'yup';

import { NewServiceContext } from '../..';

export const ObsService = ({ protocolData }) => {
  const { updateProtocolData, setActiveStep } = useContext(NewServiceContext);

  const typePeriod = [
    { label: 'Manhã', value: 'Manhã' },
    { label: 'Tarde', value: 'Tarde' },
    { label: 'Flexível', value: 'Flexível' },
  ];

  const validationSchema = Yup.object().shape({
    input_obs: Yup.string().required('Motivo do atendimento é obrigatório'),
    period: Yup.string().required('Período é obrigatório'),
    input_data: Yup.date().nullable(),
    time: Yup.mixed()
      .nullable() // Permite o valor null
      .test('is-valid-time', 'Horário é obrigatório quando a data é escolhida', function (value) {
        const { input_data } = this.parent; // Acessa o campo de data
        if (input_data && !value) {
          return false; // Se a data foi escolhida, exige o horário
        }
        return true; // Caso contrário, time pode ser null ou vazio
      }),
  });

  const handleSubmit = async (values) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await updateProtocolData({
        obs: values.input_obs,
        date: values.input_data || null,
        time: values.time || null,
        period: values.period,
      });
      setActiveStep(3);
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro ao salvar observação');
    }
  };

  return (
    <>
      <PageTitle> Agendamento </PageTitle>

      <Formik
        initialValues={{ input_obs: '', input_data: null, time: null }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {
          // Verificando se os campos obrigatórios estão preenchidos
          const isButtonDisabled = !props.values.input_obs || !props.values.period;

          return (
            <Form>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  <strong>Protocolo: {protocolData.protocol}</strong>
                </Typography>
              </Grid>
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Field
                      size='small'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data Atendimento'
                      name='input_data'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Field
                      size='small'
                      name='time'
                      variant='outlined'
                      fullWidth
                      inputVariant='outlined'
                      label='Horário'
                      component={Time}
                      defaultValue='00:00'
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Field
                      size='small'
                      name='period'
                      label='Período'
                      variant='outlined'
                      options={typePeriod}
                      component={Select}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      size='small'
                      loading={props.isSubmitting}
                      name='input_obs'
                      variant='outlined'
                      fullWidth
                      label='Motivo do atendimento'
                      component={Text}
                      multiline
                      minRows={10}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3} lg={3}></Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Button
                      type='submit'
                      disabled={isButtonDisabled || props.isSubmitting}
                      loading={props.isSubmitting}
                      endIcon={<ArrowForwardIcon />}
                      fullWidth
                    >
                      Avançar
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
