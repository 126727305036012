import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';

import { Button, DatePicker, Select } from 'components';
import { Field, Form, Formik } from 'formik';
import { downloadcsv } from 'helpers/downloadcsv';
import { PageTitle } from 'pages/Layout/PageTitle';
import { providersService } from 'services';
// import XLSX from 'xlsx';

const options = [
  { label: 'Scrap / Encerradas', value: '0' },
  { label: 'Etapas por Ano', value: '1' },
];

const optionsFiltro = [
  { label: 'Data de Abertura', value: 'Dt_Abert' },
  { label: 'Data Ult Log', value: 'dtUltLog' },
];

const optionsFiltroYear = [
  { label: 'Data de Abertura', value: 'a.Dt_Abert' },
  { label: 'Data Ult Log', value: 'a.dtUltLog' },
];

function formatDate(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const AgingFinalizedScrapReport = () => {
  const today = new Date();
  const now = formatDate(new Date());
  const [inputData, setInputData] = useState(new Date(new Date().setDate(today.getDate() - 30)));
  const [outputData, setOutputData] = useState(new Date());
  const [optionsYear, setOptionsYear] = useState([]);

  const handleDownloadReport = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    const startDate = new Date(inputData);
    const endDate = new Date(outputData);
    const diffInDays = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    if (diffInDays > 365) {
      // Informar ao usuário e rejeitar o envio
      toast.error('A data de saída deve ser dentro de 1 ano dias após a data de entrada.');
      return;
    }
    toast.warning('Gerando o relatório...');
    try {
      const { data } = await providersService.getAgingReportFinishAndScrap({
        input_date: inputData,
        output_date: outputData,
        type_report: values.type_report,
        year: values.year,
        type_filter: values.type_filter,
      });

      await downloadcsv(`Relatório_de_aging_finalizado_scrap${now}`, data);

      toast.success('Relatório gerado com sucesso');
    } catch (err) {
      toast.error(err?.response?.data?.message || 'Erro na geração do relatório');
    } finally {
      setSubmitting(false);
    }
  };

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    // Adicionar os últimos 10 anos até o ano atual
    for (let i = currentYear; i >= currentYear - 10; i--) {
      years.push({ label: `${i}`, value: i });
    }

    return years;
  };

  useEffect(() => {
    setOptionsYear(generateYearOptions());
  }, []);

  return (
    <>
      <PageTitle>Relatório de aging finalizado e scrap</PageTitle>

      <Formik
        initialValues={{
          input_data: inputData,
          output_data: outputData,
          type_report: '',
          year: '',
          type_filter: '',
        }}
        onSubmit={handleDownloadReport}
      >
        {(props) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={2} lg={2}>
                <Field
                  size='small'
                  name='type_report'
                  variant='outlined'
                  fullWidth
                  label='Tipo Relatório'
                  component={Select}
                  options={options}
                />
              </Grid>
              {props.values.type_report === '0' && (
                <>
                  <Grid item xs={2} lg={2}>
                    <Field
                      size='small'
                      name='type_filter'
                      variant='outlined'
                      fullWidth
                      label='Tipo Filtro'
                      component={Select}
                      options={optionsFiltro}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='input_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data inicial'
                      component={DatePicker}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Field
                      size='small'
                      name='output_data'
                      variant='inline'
                      fullWidth
                      inputVariant='outlined'
                      label='Data final'
                      component={DatePicker}
                    />
                  </Grid>
                </>
              )}

              {props.values.type_report === '1' && (
                <>
                  <Grid item xs={2} lg={2}>
                    <Field
                      size='small'
                      name='type_filter'
                      variant='outlined'
                      fullWidth
                      label='Tipo Filtro'
                      component={Select}
                      options={optionsFiltroYear}
                    />
                  </Grid>
                  <Grid item xs={2} lg={2}>
                    <Field
                      size='small'
                      name='year'
                      variant='outlined'
                      fullWidth
                      label='Ano'
                      component={Select}
                      options={optionsYear}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} lg={4}>
                <Button type='submit' fullWidth loading={props.isSubmitting}>
                  exportar relatório
                </Button>
              </Grid>
            </Grid>
            {useEffect(() => {
              setInputData(props.values.input_data);
              setOutputData(props.values.output_data);
            }, [props])}
          </Form>
        )}
      </Formik>
    </>
  );
};
