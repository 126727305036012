import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Dialog, SignaturePad } from 'components';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

export const DialogSignaturesReturn = ({ open, onClose, line, observacao }) => {
  if (!open) return null;
  const canvasRef = useRef(null);

  const [signatureType, setSignatureType] = useState('client');
  const [clientSignature, setClientSignature] = useState(null);
  const [technicianSignature, setTechnicianSignature] = useState(null);
  const user = useContextSelector(AuthContext, (context) => context.user);
  const url = '/satisfaction-search';
  const fullUrl = `${window.location.origin}${url}`;

  useEffect(() => {
    if (technicianSignature) {
      const downloadPdf = async () => {
        try {
          const { data } = await inHomeService.finishedServiceReturnDriver({
            infoService: line,
            clientSignature: clientSignature,
            technicianSignature: technicianSignature,
            observacao: observacao,
            tec: user.name,
            clientUrl: fullUrl,
            clientEmail: line.email,
          });

          const blob = new Blob([data], { type: 'application/pdf' });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Laudo-${line.protocol}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);

          // Enviar o PDF para o backend (AWS)
          await uploadPdfToAws(blob);

          await inHomeService.finishReturnDriver({
            line: line,
            user: user.name,
            user_id: user.id,
          });
          toast.success('Atendimento finalizado com sucesso!');
          onClose();
        } catch (error) {
          console.error(
            'Erro ao chamar finalizar atendimento',
            error?.response || error?.message || error,
          );
          toast.error('Erro ao chamar finalizar atendimento!');
        }
      };
      const uploadPdfToAws = async (pdfBlob) => {
        const base64Pdf = await convertBlobToBase64(pdfBlob);

        // Preparar o objeto com a string base64
        const payload = {
          pdfLaudo: base64Pdf.split(',')[1],
          fileName: `Laudo-${line.protocol}.pdf`,
          infoService: line,
        };
        try {
          const response = await inHomeService.uploadImagesAws(payload);
        } catch (error) {
          console.error('Error uploading PDF:', error);
          toast.error('Erro ao subir laudo na nuvem!');
        }
      };
      downloadPdf();
    }
  }, [
    technicianSignature,
    clientSignature,
    fullUrl,
    onClose,
    observacao,
    user.id,
    user.name,
    line,
  ]);

  const handleSaveImage = async (blob) => {
    if (signatureType === 'client') {
      const base64ClientSignature = await convertBlobToBase64(blob);
      setClientSignature(base64ClientSignature);
      setSignatureType('technician');
    } else {
      const base64TechnicianSignature = await convertBlobToBase64(blob);
      setTechnicianSignature(base64TechnicianSignature);
    }
  };

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };
  return (
    <>
      <Dialog maxWidth={'md'} open={open}>
        <Dialog.Title onClose={onClose}>Assinaturas</Dialog.Title>
        <Dialog.Content>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='h6' gutterBottom>
                {signatureType === 'client' ? 'Assinatura do Cliente' : 'Assinatura do Técnico'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SignaturePad ref={canvasRef} onSaveImage={handleSaveImage} />
            </Grid>
          </Grid>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
