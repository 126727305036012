import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';

import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Button, Dialog, Mask, Text } from 'components';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogSignatures } from '../DialogSignatures';

export const DialogScheduling = ({ open, onClose, line }) => {
  if (!open) {
    return null;
  }

  const user = useContextSelector(AuthContext, (context) => context.user);

  const [parts, setParts] = useState([]);
  const [openDialogSignatures, setOpenDialogSignatures] = useState(false);
  const [realRelato, setRealRelato] = useState('');
  const [backup, setBackup] = useState(false);
  const [repair, setRepair] = useState(false);
  const [withDefect, setWithDefect] = useState(false);
  const [photos, setPhotos] = useState({
    photo1: null,
    photo2: null,
    photo3: null,
    photo4: null,
  });

  const [previewPhotos, setPreviewPhotos] = useState({
    photo1: null,
    photo2: null,
    photo3: null,
    photo4: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await inHomeService.findPartByService({ service_id: line.service_id });
      setParts(data);
    };
    if (open) {
      fetchData();
    }
  }, [open, line.service_id]);

  const handleFileChange = (event, photoKey) => {
    const file = event.target.files[0];

    // Verifica se o arquivo é uma imagem
    if (file && !file.type.startsWith('image/')) {
      toast.error('Por favor, selecione apenas arquivos de imagem.');
      return;
    }

    if (file) {
      // Atualizar pré-visualização e o arquivo real
      setPreviewPhotos((prevPhotos) => ({
        ...prevPhotos,
        [photoKey]: URL.createObjectURL(file), // Para pré-visualização
      }));

      setPhotos((prevFiles) => ({
        ...prevFiles,
        [photoKey]: file, // Para upload
      }));
    }
  };

  const handleUpdateAmount = async (props, index) => {
    try {
      const values = props.values; // Todos os valores do formulário
      const currentItem = parts[index]; // Item específico com base no index
      const updatedAmountUsed = values[`amount_used-${currentItem.id}`]; // Valor do campo 'Utilizado' para o item atual

      if (currentItem.amount < updatedAmountUsed) {
        toast.error('Quantidade utilizada não pode ser maior que a requisitada!');
        return;
      }
      // Lógica para processar o valor atualizado para esse item

      const { data } = await inHomeService.updateUsedAmount({
        idPart: currentItem.id,
        amountUsed: updatedAmountUsed,
        user: user.id,
        service_id: line.service_id,
        previously_id: line.status_id,
      });
      toast.success('Quantidade de peças utilizadas atualizadas com sucesso!');
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(
        error?.response?.data?.message || 'Erro ao atualizar quantidade de peças utlizadas',
      );
    }
  };

  const handleOpenDialogSingnatures = () => {
    setOpenDialogSignatures(true);
  };

  const handleCloseDialogSignatures = () => {
    setOpenDialogSignatures(false);
    onClose();
  };

  const handleFinishedService = async () => {
    try {
      if (realRelato === '') {
        return toast.error('Por favor, adicione o relato real do atendimento.');
      }
      if (!photos.photo1 || !photos.photo2) {
        return toast.error('Por favor, adicione fotos do atendimento.');
      }

      handleOpenDialogSingnatures();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error('Erro');
    }
  };
  console.log(backup, repair, withDefect);
  return (
    <>
      <DialogSignatures
        open={openDialogSignatures}
        onClose={handleCloseDialogSignatures}
        photos={photos}
        line={line}
        realRelato={realRelato}
        backup={backup}
        repair={repair}
        withDefect={withDefect}
      />

      <Dialog open={open}>
        <Dialog.Title onClose={onClose}>Atendimento {line.protocol}</Dialog.Title>
        <Dialog.Content>
          <Box boxShadow={2} p={3} mb={2}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox style={{ color: '#512da8' }} onChange={() => setBackup(!backup)} />
                }
                label='Será necessário backup ?'
              />
            </FormGroup>
          </Box>
          {/* Seção de Informações do Cliente */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Informações do Cliente</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>CPF/CNPJ: </strong>
                  <Mask type='document'>{line.cgc}</Mask>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Nome: </strong> {line.name}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Email: </strong> {line.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Número: </strong>
                  <Mask type='phone'>{line.client_cellphone}</Mask>
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Seção de Endereço */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Endereço</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>CEP: </strong> {line.zipcode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Rua: </strong> {line.street}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Número: </strong> {line.number}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography>
                  <strong>Cidade: </strong> {line.city}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Seção de Relato */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Relato do cliente</strong>
            </Typography>
            <Box bgcolor='#f5f5f5' borderRadius={4} p={2}>
              <Typography variant='body1'>{line.comment}</Typography>
            </Box>
          </Box>

          {/* Seção de Relato Real */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Relato constatado</strong>
            </Typography>
            <Formik initialValues={{}}>
              {(props) => (
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: '#512da8' }}
                            onChange={() => setRepair(!repair)}
                          />
                        }
                        label='Reparado'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: '#512da8' }}
                            onChange={() => setWithDefect(!withDefect)}
                          />
                        }
                        label='Com Defeito'
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <Field
                      name='relato'
                      label='Relato'
                      component={Text}
                      variant='outlined'
                      size='small'
                      multiline
                      minRows={5}
                      onBlur={() => setRealRelato(props.values.relato)}
                    />
                  </Grid>
                </Grid>
              )}
            </Formik>
          </Box>

          {/* Seção de Components */}
          <Box boxShadow={2} p={3} mb={2} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Componentes</strong>
            </Typography>
            <Box bgcolor='#f5f5f5' borderRadius={4} p={2} style={{ overflowY: 'auto' }}>
              <Formik
                initialValues={parts.reduce((acc, item, index) => {
                  acc[`amount_used-${item.id}`] = item.amount_used || 0;
                  return acc;
                }, {})}
                enableReinitialize
              >
                {(props) => (
                  <Form>
                    {parts.map((item, index) => (
                      <Box key={index} mb={3}>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Field
                              name={`partnumber-${index}`}
                              label='Partnumber'
                              component={Text}
                              variant='outlined'
                              size='small'
                              value={item.part_number}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name={`amount-${index}`}
                              label='Qtd'
                              component={Text}
                              variant='outlined'
                              size='small'
                              type='number'
                              value={item.amount}
                              disabled
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name={`amount_used-${item.id}`}
                              label='Utilizado'
                              component={Text}
                              variant='outlined'
                              size='small'
                              type='number'
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {/* Passa o índice atual para a função handleUpdateAmount */}
                            <Button onClick={() => handleUpdateAmount(props, index)} fullWidth>
                              Atualizar
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </Form>
                )}
              </Formik>
            </Box>
          </Box>

          {/* Seção de Upload de Fotos */}
          <Box boxShadow={2} p={3} borderRadius={4} display='flex' flexDirection='column'>
            <Typography variant='h6' gutterBottom>
              <strong>Fotos</strong>
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo1 ? (
                    <img
                      src={previewPhotos.photo1}
                      alt='Foto 1'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <Typography variant='body1' align='center'>
                      <CameraAltIcon />
                    </Typography>
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleFileChange(e, 'photo1')}
                    style={{ display: 'none' }}
                    id='upload-photo1'
                    capture='environment'
                  />
                  <label htmlFor='upload-photo1'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo2 ? (
                    <img
                      src={previewPhotos.photo2}
                      alt='Foto 2'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <CameraAltIcon />
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    capture='environment'
                    onChange={(e) => handleFileChange(e, 'photo2')}
                    style={{ display: 'none' }}
                    id='upload-photo2'
                  />
                  <label htmlFor='upload-photo2'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo3 ? (
                    <img
                      src={previewPhotos.photo3}
                      alt='Foto 3'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <CameraAltIcon />
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleFileChange(e, 'photo3')}
                    style={{ display: 'none' }}
                    id='upload-photo3'
                    capture='environment'
                  />
                  <label htmlFor='upload-photo3'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Box
                  border='1px dashed grey'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  p={2}
                  height={200}
                  position='relative'
                >
                  {previewPhotos.photo4 ? (
                    <img
                      src={previewPhotos.photo4}
                      alt='Foto 4'
                      style={{ maxWidth: '100%', maxHeight: '100%' }}
                    />
                  ) : (
                    <CameraAltIcon />
                  )}
                  <Input
                    type='file'
                    accept='image/*'
                    onChange={(e) => handleFileChange(e, 'photo4')}
                    style={{ display: 'none' }}
                    id='upload-photo4'
                    capture='environment'
                  />
                  <label htmlFor='upload-photo4'>
                    <Button variant='contained' component='span'>
                      Escolher Foto
                    </Button>
                  </label>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button onClick={() => handleFinishedService()} fullWidth>
                  Finalizar
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Dialog.Content>
      </Dialog>
    </>
  );
};
