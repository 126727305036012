import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MapIcon from '@mui/icons-material/Map';
import { Button, DatePicker, Mask } from 'components';
import formatter from 'date-fns/format';
import { Field, Form, Formik } from 'formik';
import { AuthContext } from 'hooks/useAuth';
import { PageTitle } from 'pages/Layout/PageTitle';
import { inHomeService } from 'services';
import { useContextSelector } from 'use-context-selector';

import { DialogAddres } from './DialogAddress';
import { DialogRoutes } from './DialogRoutes';
import { DialogScheduling } from './DialogScheduling';

export const TechnicianScreen = () => {
  const [table, setTable] = useState([]);
  const [openAddress, setOpenAddress] = useState(false);
  const [openScheduling, setOpenScheduling] = useState(false);
  const [openDialogRoutes, setOpenDialogRoutes] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [line, setLine] = useState();
  const [dateChoosed, setDateChoosed] = useState();
  const [collapsed, setCollapsed] = useState({});
  const user = useContextSelector(AuthContext, (context) => context.user);

  const fetchData = useCallback(
    async (date) => {
      const { data } = await inHomeService.findServicesByTec({
        id_tec: user.id,
        dateSchedule: date ? date : new Date(),
      });
      setTable(data);
    },
    [user.id],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleCollapse = (index) => {
    setCollapsed((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const handleOpenAddress = async (line) => {
    setLine(line);
    setOpenAddress(true);
  };

  const handleOpenScheduling = async (line) => {
    setLine(line);
    setOpenScheduling(true);
  };

  const handleCloseScheduling = async () => {
    setOpenScheduling(false);
    fetchData(dateChoosed);
  };

  const handleOpenDialogRoutes = async () => {
    setOpenDialogRoutes(true);
  };

  const handleCloseDialogRoutes = async () => {
    setOpenDialogRoutes(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = formatter(date, 'dd/MM/yyyy HH:mm');
    return `${formattedDate}`;
  };

  const handleCheckIn = async (item) => {
    setIsLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          const address = await getAddressFromCoordinates(latitude, longitude);
          try {
            const { data } = await inHomeService.checkIn({
              service_id: item.service_id,
              address: address,
              user: user.id,
            });
            toast.success('Check In Realizado!');
            setIsLoading(false);
            fetchData(dateChoosed);
          } catch (error) {
            toast.error(error?.response?.data?.message || 'Erro ao realizar Check In');
            console.error(error?.response?.data?.message || error.message);
            setIsLoading(false);
          }
        },
        (error) => {
          console.error('Erro ao buscar geolocalização:', error.message);
          setIsLoading(false);
        },
      );
    } else {
      console.error('Geolocalização não suportada pelo navegador.');
      setIsLoading(false);
    }
  };

  const getAddressFromCoordinates = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data && data.address) {
        return `${data.address.suburb}, ${data.address.city}, ${data.address.country}, ${data.address.postcode}`;
      } else {
        console.error('Não foi possível obter o endereço.');
        return null;
      }
    } catch (error) {
      console.error('Erro na requisição Nominatim:', error);
      return null;
    }
  };

  const handleRequestBackup = async (item) => {
    setIsLoading(true);
    try {
      const { data } = await inHomeService.requestProductBackup({
        protocol: item.protocol,
        service_id: item.service_id,
        serial: item.serial,
        repair_product: item.pdv_product,
        tec_id: user.id,
        previously_status_id: item.status_id,
      });
      toast.success('Produto de backup solicitado com sucesso!');
      setIsLoading(false);
      fetchData();
    } catch (error) {
      console.error(error?.response?.data?.message || error?.message);
      toast.error(error?.response?.data?.message || 'Erro ao solicitar backup');
      setIsLoading(false);
    }
  };

  console.log('table', table);
  return (
    <>
      <DialogRoutes open={openDialogRoutes} onClose={handleCloseDialogRoutes} table={table} />
      <DialogScheduling open={openScheduling} onClose={handleCloseScheduling} line={line} />
      <DialogAddres open={openAddress} onClose={() => setOpenAddress(false)} line={line} />
      <Box boxShadow={2} p={2}>
        <Formik
          initialValues={{ date_service: new Date() }}
          onSubmit={(values) => {
            fetchData(values.date_service);
          }}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <PageTitle>Atendimentos</PageTitle>
              <Field
                name='date_service'
                label='Data do atendimento'
                component={DatePicker}
                size='small'
                variant='inline'
                fullWidth
                inputVariant='outlined'
                value={values.date_service}
                onChange={(date) => {
                  setFieldValue('date_service', date);
                  setDateChoosed(date);
                  fetchData(date);
                }}
              />
            </Form>
          )}
        </Formik>

        <br />

        <Grid container spacing={2}>
          <Grid item xs={10} sm={8}></Grid>
          <Grid item xs={1} sm={1}>
            <IconButton onClick={() => handleOpenDialogRoutes()}>
              <MapIcon style={{ color: '#512da8' }} />
            </IconButton>
          </Grid>
          {table.map((item, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12}>
                <Button fullWidth>
                  <Grid container spacing={1} direction='row'>
                    <Grid item xs={6} sm={6} style={{ alignSelf: 'center', padding: '2px' }}>
                      {`${formatDate(item?.date_Scheduling)}`}
                    </Grid>
                    <Grid item xs={2} sm={2} style={{ alignSelf: 'center' }}>
                      {`${item?.protocol}`}
                    </Grid>
                  </Grid>

                  <IconButton
                    style={{ color: '#fff' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleCollapse(index);
                    }}
                  >
                    {collapsed[index] ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </Button>
              </Grid>
              {/* Collapse para exibir mais detalhes do item */}
              <Grid item xs={12}>
                <Collapse in={collapsed[index]}>
                  {/* Renderiza as informações em um formato transposto */}
                  <Box p={2} border={1} borderColor='grey.400' borderRadius={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography>
                          <strong>Protocolo:</strong>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>{item.protocol}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>
                          <strong>Cliente:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{item.name}</Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>
                          <strong>Celular:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>
                          <Mask type='phone'>{item.client_cellphone}</Mask>
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>
                          <strong>Endereço:</strong>
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <LocationOnIcon
                          style={{ color: '#512da8' }}
                          onClick={() => handleOpenAddress(item)}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Typography>
                          <strong>Detalhes:</strong>
                        </Typography>
                      </Grid>
                      {item.checkin !== null ? (
                        <>
                          <Grid item xs={6}>
                            <AddCircleOutlineIcon
                              style={{ color: '#512da8' }}
                              onClick={() => handleOpenScheduling(item)}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={6}>
                            <Typography>
                              <strong>Realize o Check In</strong>
                            </Typography>
                          </Grid>
                        </>
                      )}

                      <Grid item xs={6}>
                        <Typography>
                          <strong>Check:</strong>
                        </Typography>
                      </Grid>
                      {item.checkin == null ? (
                        <>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              loading={isLoading}
                              onClick={() => handleCheckIn(item)}
                              color='secondary'
                            >
                              Check In
                            </Button>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Typography>Check In Realizado</Typography>
                        </>
                      )}
                    </Grid>
                  </Box>
                </Collapse>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
};
